@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
::selection {
  color: #fff;
  background-color: #5e17eb;
}
body,html{
  font-family: 'Poppins', sans-serif !important;
  background-color: #E8E6E6;
  word-break: break-word;
}
.login-footer a{
  padding: 0 10px;
  text-decoration: none;
}
.floating-btn {
  display: none;
  position: fixed;
  bottom: 0px;
  right: 30px;
  z-index: 1000;
}
.bg-primary, .theme{
  background-color: #5e17eb !important;
}
.outline-primary{
  border-color: #5e17eb;
  color: #5e17eb;
}
.outline-primary:hover,.outline-primary:focus{
  background-color: #5e17eb !important;
  color: #fff !important;
}
.btn-primary:hover, .btn-primary:focus{
  background-color: #000 !important;
  color: #fff !important;
}
.login-right{
  position: absolute;
  top: 70px;
  right: 0px;
}
.login-left{
  position: absolute;
  bottom: 100px;
  left: 0px;
}
.bg-grey{
  background-color: #fefefe;
}

.login-main{
  min-height: calc(100vh - 180px);
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.login-main > div{
  max-width: 550px;
  margin: 0 auto;
}
.login-main-inner{
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-banner{
  background-color: #5e17eb;
  height: 400px;
  color: #fff;
}
.theme-padding{
  padding: 80px 0 ;
}
.home-banner-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.action-section{
  margin-top: -50px;
}
.action-item{
  background-color: #fff;
  display: flex;
  gap: 40px;
  margin-bottom: 30px;
}

.action-item .action-img{
  display: flex;
  width: 140px;
  align-items: center;
  justify-content: center;
  padding: 30px;
  border-right: 1px solid #eaeaea;
}
.action-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.action-title h2{
  margin-bottom: 0px;
}
.action-title p{
  margin-top: 0px;
}
.library-cards-inner{
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
.library-card{
  flex: 0 0 auto;
  width: calc(33.33% - 30px);
  margin-bottom: 30px;
}
div.library-card{
  border-radius: 0;
  box-shadow: none;
}

/* book-action */
.book-action{
  text-align: center;
}
.book-action-item{
  background-color: #fff;
  padding: 40px;
  border-radius: 30px;
  max-width: 800px;
  margin: 60px auto;
  text-align: left;
}
.avatar-section{
  background-color: #18253A;
  border-radius: 4px;
  padding: 80px;
  padding-bottom: 30px;
  text-align: center;
  position: relative;
  margin-top: 50px;
}
.user-avatar{
  position: absolute;
  top: -45px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 100%;
  outline: 10px #18253A solid;
  margin-bottom: 30px;
}

.next-prev-btns{
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  
}
.next-btn{
  margin-left: auto !important;
}

.loading{
  text-align: center;
}
.loader {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #5e17eb;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin: 0 auto;
}
.pick-date-time .loader{
  margin-top: 40px;
  margin-bottom: 40px;
}
div.pick-date-time{
  padding: 0;
}
.current-needs {
  display: flex;
  gap: 30px;
}
.current-needs h2{
  margin-top: 0;
}

.quiz-section .quiz-radio{
  margin-left: 0 !important;
  width: 100%;
}
.quiz-radio .MuiRadio-root{
  display: none;
}
.quiz-section form .MuiFormControl-root{
  margin: 0 ;
}

.quiz-radio .MuiRadio-root + .MuiTypography-root{
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 15px;
  border: 2px solid #5e17eb;
  width: 100%;
  color: #5e17eb;
}
.quiz-radio .MuiRadio-root.Mui-checked + .MuiTypography-root{
  color: #fff;
  background-color: #5e17eb;
}

label#reportType, label#ethnicity {
  -webkit-transform: translate(14px, -9px) scale(0.75);
  -moz-transform: translate(14px, -9px) scale(0.75);
  -ms-transform: translate(14px, -9px) scale(0.75);
  transform: translate(14px, -9px) scale(0.75);
  background-color: #fff;
}


/* New Footer */
.login-new-footer{
  background-color: #00115F;
  color: #fff;
  padding: 80px 0;
}

.login-new-footer>div{
  display: flex;
  gap: 50px;
}
.login-new-footer h2{
  font-size: 32px;
}
.footer-left{
  flex: 2;
}
.footer-left p{
  font-size: 14px;
}
.footer-right{
  flex: 1;
  margin-top: 80px;
}
.footer-right a{
  color: #FF0040;
  font-size: 16px;
  padding: 0;
}


/* .unauthorized-page */
.unauthorized-page{
  height: calc(100vh - 145px);
}
.unauthorized-card{
  background-color: #fff;
  padding: 50px;
  text-align: center;
  box-shadow: 5px 5px 20px rgba(0,0,0,0.05);
  max-width: 400px;
  border-radius: 6px;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media screen and (max-width: 1080.98px){
  .login-main-wrapper.book-your-demo > div:first-child{
    max-width: 50%;
    padding-left: 0;
  }
  .login-main-wrapper h2{
    padding-right: 0;
    font-size: 46px;
  }
  .login-main-wrapper h4{
    font-size: 32px;
  }
}

@media screen and (max-width: 991.98px){
  .login-main-wrapper.book-your-demo > div:first-child{
    max-width: 100%;
    padding: 0;
  }
  .login-main-wrapper {
    flex-wrap: wrap;
  }
  .login-main-wrapper.login-main > div{
    max-width: 100%;
    width: 100%;
  }
}

@media screen and (max-width: 767.98px){
  .login-new-footer > div{
    flex-wrap: wrap;
  }
  .login-main-wrapper h2{
    font-size: 36px;
  }
  .login-right, .login-left{
    max-width: 50px !important;
    height: auto !important;
  }
  
  .library-cards-inner{
    display: block;
  }
  .library-card{
    flex: auto;
    width: 100%;
  }
  .action-title{
    padding: 10px ;
  }
  html body .p-20{
    padding: 20px !important;
  }
  html body .md-p-20{
    padding: 20px !important;
  }
  .home-banner{
    height: auto;
  }
  .login-footer > div{
    flex-wrap: wrap;
  }
  .footer-left,.footer-right{
    flex: 100%;
  }
  .footer-right{
    margin-top: 0px;
  }
}

@media screen and (max-width: 599.98px){ 
  .contact-input{
    padding: 15px 0 0 0!important;
  }
  .action-item .action-img{
    width: 100px;
  }
  .home-banner-inner{
    display: block;
  }
  .action-item{
    gap: 10px
  }
  .md-w-100{
    width: 100%;
  }
  .our-coaches {
    display: grid !important;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 5px;
  }
} 

@media screen and (max-width: 480.98px){
  .our-coaches img{
    width: 95%;
    max-width: 90%;
  }
}

@media screen and (max-width: 390.98px){
  .consultab .MuiTab-root {
    width: 100%;
    margin-bottom: 5px;
}

}

@media screen and (max-width: 290.98px){
  .circle-help {
    max-height: 83px!important;
    max-width: 57px !important;
}
}

.css-o69gx8-MuiCardMedia-root {
  width: auto 
}

.book-your-demo{
  padding: 40px 0;
}

.book-your-demo > div:first-child{
  max-width: 100%;
}
select{
  padding-left: 10px!important;
}
 option{
  padding: 20px 20px 20px 20px !important;
}

html body select option:hover {
  color: yellow !important;
}

.request-demo-wrapper {
  background-color: rgb(232, 230, 255);
}

