input:focus {
  outline: none;
}
.mullish {
  font-family: 'Mulish', sans-serif !important;
}
.inter-family {
  font-family: 'Inter', sans-serif !important;
}
.weight-400 { font-weight: 400 !important; }
.weight-500 { font-weight: 500 !important; }
.weight-600 { font-weight: 600 !important; }
.weight-700 { font-weight: 700 !important; }
.weight-800 { font-weight: 800 !important; }


