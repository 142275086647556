@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");
@import url("https://fonts.googleapis.com/css?family=Mulish&display=swap");
body,
html {
  font-family: "Poppins", sans-serif;
  background-color: #f0f4fa;
  overflow-x: hidden;
  min-height: 100vh;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.hide {
  display: none;
}

.valid {
  color: limegreen;
  margin-left: 0.25rem;
}

.invalid {
  color: red;
  margin-left: 0.25rem;
}

.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}
.line {
  display: inline-block;
}
.contact-input label {
  margin-top: 0px;
  top: -10px;
}

.book-action-inner .css-169iwlq-MuiCalendarPicker-root {
  width: 400px;
}
.book-action-inner .MuiInput-underline::before {
  border: none;
}
.book-action-inner
  .css-195y93z-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  color: #5e17eb;
  background-color: transparent;
}
.book-action-inner .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root {
  font-size: 20px;
}
.currentRadio .css-hyxlzm {
  visibility: hidden;
}

.selectdiv .MuiRating-root label:last-child {
  display: none;
}
.selectdiv .MuiRating-visuallyHidden:last-child {
  display: none;
}

.selectdiv .MuiRating-root.border-bg {
  position: relative;
  z-index: 1;
}
.selectdiv .MuiRating-root.border-bg::after {
  content: "";
  background-color: #000;
  width: 100%;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 0px;
  z-index: -1;
}
.border-bg .css-1c99szj-MuiRating-icon {
  color: #ffffff !important;
  border: 1px solid #6c63ff;
  border-radius: 100%;
  background-color: #fff;
}

.border-bg .css-gyw2pj-MuiRating-root .MuiRating-iconFilled {
  background-color: #6c63ff !important;
}
.border-bg .MuiRating-visuallyHidden {
  position: relative !important;
  font-size: 13px;
  margin: 10px 0px !important;
  display: block;
  margin: 2px 0px 0px 0px !important;
  visibility: visible;
  overflow: visible !important;
  white-space: inherit !important ;
}
.border-bg input {
  display: none !important;
  width: 100% !important;
}
.consultab button.MuiTab-root.Mui-selected {
  color: #5e17eb;
  border: 1px solid #5e17eb;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 0px;
  background-color: transparent;
}
.consultab .MuiTab-root {
  background-color: #f1f3f6;
  width: 24.5%;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 0px;
}
.consultab .MuiTabs-flexContainer {
  justify-content: space-between;
}

.selectdiv .border-bg .MuiButtonBase-root {
  padding: 0;
  background-color: #f0f4fa;
  position: relative;
}
.selectdiv .MuiFormControlLabel-root {
  position: relative;
}
.selectdiv .border-bg {
  position: relative;
  z-index: 1;
  width: 98%;
  margin: 0 auto;
}

.selectdiv .border-bg .MuiFormGroup-root::after {
  content: "";
  background-color: #000;
  width: 95%;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 1.2%;
  z-index: -1;
  text-align: center;
  display: flex;
  justify-content: center;
}

.selectdiv .border-bg .MuiFormControlLabel-label {
  position: absolute;
  top: 30px;
  left: 0px;
  font-size: 13px;
  width: 60px;
}
.selectdiv .border-bg .MuiFormControlLabel-root:last-child .MuiTypography-root {
  left: -25px;
  text-align: right;
}
.selectdiv label:last-child .MuiRating-icon {
  justify-content: end;
}
.selectdiv .starrate label {
  width: 100%;
}

.selectdiv .MuiRating-icon {
  justify-content: center !important;
}
html body .selectdiv .MuiRating-iconFilled {
  justify-content: center !important;
}

.selectdiv .Mui-checked {
  color: #5e17eb;
}

.starrate {
  width: 117%;
  margin-left: -9%;
}

.iconHover {
  opacity: 1;
  size: 2rem;
}

.iconHover:hover svg {
  opacity: 1.5;
  transform: scale(1.5);
  transition: all 0.2s ease-in;
}
